<script>
import _ from "lodash";
import PatientService from "@/services/patient.service";
import {
  FIELD_TYPES,
  SINGLE_DRAWING_FIELDS,
  MEDICAL_CODES_FIELDS,
} from "@/constants/patientcard/fields/types";
import { mapGetters } from "vuex";


export default {
  props: {
    session: {},
    patient: {},
    owner: {},
    hideCloneShfs: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSessionTypeActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      names: {},
    };
  },
  computed: {
     ...mapGetters("ui", ["recommendationsVisible"]),
    visibilityCondition() {
      return () => {
        return true;
      };
    },
    foldableFields() {
      return function (isSHFS) {
        let fields = {};
        if (isSHFS) {
          fields[FIELD_TYPES.TEXT_FIELD] = [
            [FIELD_TYPES.TEXT_FIELD],
            this.textFieldsCols,
          ];
        }

        MEDICAL_CODES_FIELDS.forEach((code) => {
          fields[code] = [[...MEDICAL_CODES_FIELDS], this.codesFieldsCols];
        });

        SINGLE_DRAWING_FIELDS.forEach((singleDrawing) => {
          fields[singleDrawing] = [
            [...SINGLE_DRAWING_FIELDS],
            this.visFieldsCols,
          ];
        });
        return fields;
      };
    },
    textFieldsCols() {
      return this.$vuetify.breakpoint.mdAndUp ? 6 : 12;
    },
    codesFieldsCols() {
      return this.$vuetify.breakpoint.xl
        ? 4
        : this.$vuetify.breakpoint.mdAndUp
        ? 6
        : 12;
    },
    visFieldsCols() {
      return this.$vuetify.breakpoint.xsOnly
        ? 12
        : this.$vuetify.breakpoint.mdAndUp
        ? 4
        : 6;
    },
    fieldOrderKeys() {
      let shfs = [];
      Object.keys(this.session.shfs).forEach((name) => {
        if (this.visibilityCondition(name)) {
          shfs.push(name);
        }
      });

      let ahfs = [];
      Object.keys(this.session.ahfs).forEach((name) => {
        if (this.visibilityCondition(name)) {
          ahfs.push(name);
        }
      });

      return {
        shfs,
        ahfs,
      };
    },
    customProps() {
      return {
        shfs: this.customFieldsProps(this.session.shfs, true),
        ahfs: this.customFieldsProps(this.session.ahfs),
      };
    },
  },
  methods: {
    onChangedCodes: _.debounce(function () {
      if (
        !this.recommendationsVisible ||
        !this.session.card ||
        !this.session.active ||
        !this.session.ahfs ||
        this.owner
      ) {
        return;
      }
      let codes = {
        icd9: "",
        icd10: "",
        icf: "",
      };
      const models = ["ICD9", "ICD10", "ICF"];
      models.forEach((model) => {
        if (this.session.ahfs[model].length == 0) {
          return;
        }
        codes[model.toLowerCase()] = this.session.ahfs[model][0].data.codes
          .map((code) => code.code)
          .join(",");
      });

      PatientService.getRecommendations(codes)
        .then((reply) => {
          this.$set(
            this.session.ahfs["Recommendation"][0],
            "recommendations",
            reply.recommendations
          );
        })
        .catch((error) => {
          console.log("ERRR", error);
        });
    }, 300),
    adjustCols(customProps, index) {
      const prop = customProps[index];
      if (index > 0 && prop.inrow != 0 && prop.inrow != 12 / prop.cols) {
        for (let i = 0; i < prop.inrow; i++) {
          if (index - i >= 0) {
            customProps[index - i].cols = 12 / prop.inrow;
          }
        }
      }
    },
    toggleRowColor(color) {
      return color == "transparent" ? "#F9F9F9" : "transparent";
    },
    customFieldsProps(shfs, isSHFS = false) {
      //HOW IT WORKS:
      //Firstly, we are iterating over all SHFS/AHFS and we trying to find
      //fields which could be folded (this.foldableFields). If we find,
      //then we iterating inrow value to see how many fields are in row with
      //the same column size.
      let cp = [];
      const keys = isSHFS ? this.fieldOrderKeys.shfs : this.fieldOrderKeys.ahfs;
      let types = [];
      Object.values(shfs).forEach((value) => {
        if (isSHFS && this.visibilityCondition(value.name)) {
          types.push(value.field_type);
        } else if (
          value.length > 0 &&
          this.visibilityCondition(value[0].name)
        ) {
          types.push(value[0].field_type);
        }
      });
      const foldableFields = this.foldableFields(isSHFS);
      for (let i = 0; i < keys.length; i++) {
        const shf = keys[i];
        const fieldType = types[i];
        const condition = foldableFields[fieldType];
        const prev = i > 0 ? cp[i - 1] : null;

        if (!condition) {
          this.adjustCols(cp, i - 1);
          cp.push({
            name: shf,
            cols: 12,
            color: prev ? this.toggleRowColor(prev.color) : "transparent",
            inrow: 0,
            label: this.names[shf],
          });
        } else {
          const pairs = condition[0];
          const cols = condition[1];
          let found = false;
          if (
            i - 1 >= 0 &&
            pairs.includes(types[i - 1]) &&
            prev.inrow != 12 / cols
          ) {
            cp.push({
              name: shf,
              cols: cols,
              color: prev.color,
              inrow: prev.inrow + 1,
              label: this.names[shf],
            });
            found = true;
          } else if (i + 1 <= keys.length - 1 && pairs.includes(types[i + 1])) {
            cp.push({
              name: shf,
              cols: cols,
              color: prev ? this.toggleRowColor(prev.color) : "transparent",
              inrow: 1,
              label: this.names[shf],
            });
            found = true;
          }

          if (!found) {
            this.adjustCols(cp, i - 1);
            cp.push({
              name: shf,
              cols: 12,
              color: prev ? this.toggleRowColor(prev.color) : "transparent",
              inrow: 0,
              label: this.names[shf],
            });
          }
        }
      }
      this.adjustCols(cp, keys.length - 1);
      return cp;
    },
  },
};
</script>